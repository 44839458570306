@import "../../../styles/colours.scss";

.formField-label.MuiInputLabel-root {
  font-family: Poppins,sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  color: $black;
}

.formField {
  margin-bottom: 0.5em;

  &.MuiInputBase-root {
    background-color: $white;
    font-family: Poppins,sans-serif;
  }

  .MuiFilledInput-root {
    background-color: $white;
    font-family: Poppins,sans-serif;
  }

  .MuiSelect-select {
    padding-top: 10px;
    font-family: Poppins,sans-serif;
  }
}

.formField-multiselect-item .MuiListItemText-primary {
  font-family: Poppins,sans-serif;
}
