@import "../../../styles/colours.scss";

.fileManagement-metadataSection {
  .fileManagement-metadata-controls {
    font-family: Poppins,sans-serif;
    padding: 0 0.5em;

    .fileManagement-metadata-column {
      .column-title {
        font-weight: bold;
        padding-bottom: 0.5em;
        margin-bottom: 1em;
        border-bottom: 1px solid $black;
      }

      .centerColumn-half {
        padding: 0 35px;
      }
    }
  }
}
