@import "../../styles/colours.scss";

.viewHeader {
	width: 100%;
	margin-bottom: 2em;

	.viewHeader-nav {
		width: 50%;
		.viewHeader-caption{
			position:relative;
			right: -75%;
			font-weight:600;
		}

		.viewHeader-nav-button {
			cursor: pointer;

			.backLink {
				display: flex;
				
			}
		}
		.viewHeader-title {
		}
	}
}
