@import "../../../styles/colours.scss";
@import "../../../styles/custom.scss";

.fileManagement-uploadSection {
	.fileManagement-upload-controls {
		margin: 1em 0;

		.button {
			margin: 0 10px;
		}

		.button-select,
		.button-upload {
			@include btn-primary();
		}

		.button-cancel {
			@include btn-secondary();
		}

		.button.Mui-disabled {
			@include btn-disabled();
		}
	}

	.fileManagement-upload-progress {
		.fileUpload-progressBar {
			.MuiLinearProgress-bar {
				background-color: $primaryBlue;
			}

			&.finished {
				.MuiLinearProgress-bar {
					background-color: $lightGreen;
				}
			}
		}
	}
}
