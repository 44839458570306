@import "../../styles/colours.scss";
@import "../../styles/custom.scss";

.channelManagement-form-container {
	padding: 1em;
	background-color: $componentGrayBackground;
	color:black;
	border-radius: 5px;

	.channelManagement-form-title,
	.channelManagement-section-title {
		padding-bottom: 0.5em;
		font-family: Poppins,sans-serif;

		.icon {
			font-size: 1.75em;
			margin-right: 10px;
		}
	}

	.channelManagement-form-title {
		border-bottom: 1px solid $darkGray;
	}

	.channelManagement-formSection {
		padding: 1em 0;
		border-bottom: 1px solid $darkGray;

		&:last-of-type {
			border-bottom: none;
		}
	}

	.channelManagement-submitButtons {
		.button {
			margin: 0 10px;
		}

		.button-submit {
			@include btn-primary();
		}

		.button-cancel {
			@include btn-secondary();
		}

		.button-disabled {
			@include btn-disabled();
		}
	}
}
