@import "../../styles/colours.scss";

.solutionsTabs-container {
  
  .solutionsTabs-wrapper {
    .solutionsTabs {
      .tab {
        color: $white;
        font-family: Poppins,sans-serif;
        font-size: 1.2em;
        text-transform: none;
        border-radius: 5px 5px 0 0;
        background-color:$blackAlt;
        margin-right:2px;

        &.Mui-selected {
          background-color: $primaryBlue;
          color: $white;
        }
      }
    }

    .solutionsTabs-addNewCategory {
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }
    }
  }

  .newSolutionsTab-input-wrapper {
    margin-top: 0.75em;
  }
}
