@import "./colours.scss";
@import url("//fonts.googleapis.com/css?family=Poppins:300,400,400i,600,600i");

body {
	margin: 0;
	height: 100vh;
	font-family: Poppins,sans-serif;
	overflow-y: scroll;
	background-color: black;
	color: white;


	#root {
		height: 100%;
		display: flex;
		flex-flow: column;
	}
}

a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

footer {
	margin-top: 15px;
	padding-bottom: 1em;
	font-family:Poppins, sans-serif;
	font-weight:300;
}

@mixin btn() {
	border-radius: 0;
}

@mixin btn-primary() {
	@include btn();

	color: $white;
	background-color: $primaryBlue;
	border-color: $primaryBlue;
}

@mixin btn-secondary() {
	@include btn();

	color: $black;
	background-color: $cancelButtonBackground;
	border-color: $cancelButtonBackground;
}

@mixin btn-disabled() {
	@include btn();

	color: $white;
	background-color: $disabledButtonBackground;
	border-color: $disabledButtonBackground;
	cursor: default;
}

.link {
	color: $white;
	text-decoration: none;
	cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	display: none;
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
