$black: #000000;
$blackAlt: #292929;
$primaryBlue: #3380FF;
$accessibleBlue: #004ECC;
$brightBlue: #53D7FB;
$magenta: #A30A7D;
$darkGray: #393939;
$lightGray: #e9e9e9;
$white: #ffffff;
$offWhite: #EFEFEF;
$shadowColour: #e0e0e0;
$red: #ff3334;
$componentGrayBackground: #EFEFEF;
$yellow: #ffb400;
$orange: #FFB233;
$lightGreen: #52fa99;
$cancelButtonBackground: #c3c3c3;
$disabledButtonBackground: #c3c3c3;
