@import "../../../styles/colours.scss";

.targetAudience-column {
	.target-guide {
		font-size: 0.75em;
		margin-bottom: 1em;
	}

	.targetAudience-item {
		background-color: $darkGray;
		color: $white;
		margin-bottom: 0.5em;
		padding: 0 0.5em;

		.icon {
			color: $white;
		}
	}
}
