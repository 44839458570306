@import "../../../styles/colours.scss";

.fileManagement-audienceSection {
  .fileManagement-audience-controls {
    font-family: Poppins,sans-serif;
    padding: 0 0.5em;

    .fileManagement-audience-column {
      .column-title {
        font-weight: bold;
        padding-bottom: 0.5em;
        margin-bottom: 1em;
        border-bottom: 1px solid $black;
      }
    }
  }
}
