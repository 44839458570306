@import "../../styles/colours.scss";

.solutionsBrowser-wrapper {
	margin-top: 0.5em;
	margin-top: 9px;
	background-color: $componentGrayBackground;
	padding: 10px;
	border-radius: 5px;

	.solutionsBrowser-tree-wrapper {
		padding-right: 1em;
		margin-top: 9px;
		background-color: $offWhite;
		padding: 10px;
		border-radius: 5px;
	}
}
.unauthorizedMessage{
	font-size: 36px;
}
