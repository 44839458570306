@import "../../styles/colours.scss";

.homeView {
    /* margin: auto; */
    align-self: center;
    min-width: 1440px;

    .homeView-header {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        background-position-x: right;
        background: url("../../images/HomeViewHeaderBackground.jpg") center top no-repeat;
        background-size: cover;
        height: 530px;
        max-width: 1444px;

        .homeView-title-box {
            background: rgba(0,0,0,.85);
            border-radius: 0 0 85% 0;
            min-height: 530px;
            min-width: 440px;
            padding-top: 100px;
            padding-right: 15px;
            padding-bottom: 30px;
            padding-left: 16px;
            position: relative;
            align-self: flex-start;
            box-sizing: border-box;
            width: 35%;
            z-index: 0;

            .homeView-app-title-container {
                margin-bottom: 20px;
                width: 100%;
                font-weight: 700;
                font-size: 352px;
                line-height: 54px;
                overflow: hidden;

                .homeView-app-subtitle {
                    margin-top: 20px;
                }
            }
        }
    }

    .homeView-solutionsLinks-wrapper {
        padding: 15px 0;
        background: linear-gradient(180deg, #3380ff, #195bc7 50%, #00368f);
        border-radius: 0 0 5px 5px;
    }

    .homeView-servicesLinks-wrapper {
        padding: 25px 0;
        background-color: black;
    }

    .homeView-solutionsLinks,
    .homeView-servicesLinks {
        padding-top: 1.5em;

        .homeView-solutionsLink,
        .homeView-servicesLink {
            margin: 0 2em;

            .icon {
                width: auto;
                height: 48px;
                margin-bottom: 8px;
                color: #F0F0F0;
            }

            &:hover {
                color: $primaryBlue;
            }
        }
    }

    .homeView-solutionsLinks-title {
        color: white;
        font-size: 42px;
    }

    .homeView-servicesLinks-title {
        color: white;
        font-size: 42px;
    }

    .homeView-servicesLinks-wrapper {
        /* background: $darkGray; */
        /* background-color: black; */
        color: $white;
        margin-bottom: 3em;

        .link {
            color: $white;
        }
    }
}
