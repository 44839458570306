.version-column {
	.version-input-wrapper {
		margin-bottom: 1em;

		.version-column-input,
		.version-input-label {
			flex: 1;
		}

		.version-column-input {
			width: 35px;
			margin-bottom: 0;
		}

		.version-input-label {
			margin-right: 15px;
			width: 40px;
			font-weight: bold;
		}
	}
}
