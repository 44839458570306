.selectCompany-column {
	margin: 0 1em;

	.permission-guide {
		font-size: 0.75em;

		&:last-of-type {
			margin-bottom: 1em;
		}
	}
}
