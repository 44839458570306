@import "../../styles/colours.scss";

.contactsView {
	.contactsView-container {
		/* background-color: $darkGray; */
		/* color: $white; */
		padding: 35px;

		.contactsView-container-title {
			margin-bottom: 24px;
		}

		.contacts-tables-container {
			padding: 0 12px;

			.contactsView-container-section-title {
				margin-bottom: 10px;
			}

			.contactsTable {
				min-width: 790px;
				font-family: Poppins,sans-serif;

				td {
					color: $white;
					border-bottom: none;
					border-top: 1px solid $darkGray;
				}
			}
		}
	}
}
