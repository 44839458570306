@import "../../styles/colours.scss";

.header-services {
    cursor: default;

    .services-menu {
        opacity: 0;
        visibility: hidden;
        background-color: rgba(40, 40, 40, 0.98);
        box-shadow: rgba(0, 0, 0, 0.99) 0px 5px 15px;
        box-sizing: border-box;
        padding: 0.5em 1em 1em 1em;
        left: 0;
        right: 0;
        top:60px;
        transition: opacity 0.2s ease-in-out;
        z-index: 1;

        .header-link {
            line-height: 2rem;
            padding-left:10px;
            padding-top:5px;
            padding-bottom:5px;
            padding-right:5px;
            color: white;

            &:hover {
                background-color: rgba(83, 215, 251, 0.35);
            }
        }
    }

    &:hover .services-menu {
        opacity: 1;
        visibility: visible;
    }

    &:hover {
        background-color: rgb(83, 215, 251);
        color: black;
    }
}
