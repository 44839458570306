@import "../../styles/colours.scss";

.login-button-wrapper {
    background-color: rgb(83, 215, 251);
    border-color: rgb(83, 215, 251);
    border-style: solid;
    color: black;
    font-size: 20px;
    transition: opacity 0.2s ease-in-out;

    .login-button {
        cursor: pointer;
        padding-left: 20px;
        padding-right: 10px;
    }

    &:hover .login-button .icon {
        visibility: visible;
    }
  
    &:hover {
        background-color: $blackAlt;
        color: $white;
    }
}
