@import "../../styles/colours.scss";

.solutionsBrowser-tree-container {
  background-color: $componentGrayBackground;
  color:black;
  border-right-color:red;

  .solutionsBrowser-tree-addInput-wrapper {
    /* padding: 0.5em 0.5em 0 0.5em; */

    .solutionsBrowser-tree-addInput-icon-wrapper {
      padding-bottom: 0.5em;
      border-bottom: 1px solid $lightGray;
      font-size: 0.75em;
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }
    }

    .solutionsBrowser-tree-addInput-collapse {
      margin-top: 0.3em;
    }
  }

  .solutionsBrowser-tree {
    width: 100%;

    .tree-item {
      .MuiTreeItem-label {
        font-family: Poppins,sans-serif;
        font-size: 16px;
        color:$black;
      }

      & > div {
        padding: 8px 0 5px 22px;
        box-sizing: border-box;
      }

      &.selected {
        .Mui-selected {
          background-color: $primaryBlue;
          

          .icon-arrow-down {
            color: $magenta;
          }
        }

        & > .Mui-selected > .MuiTreeItem-label > .tree-item-container > .tree-item-label-wrapper > .tree-item-actions {
          visibility: visible;
        }
      }

      &:not(.selected) {
        background-color: $componentGrayBackground;
        /* color: $white; */
        color: $darkGray;

        .tree-item-label-wrapper .tree-item-actions {
          visibility: hidden;
        }
      }

      .icon {
        font-size: 2em;
      }

      .tree-item-label-wrapper {
        .tree-item-label {
          font-family: Poppins,sans-serif;
        }

        .tree-item-actions {
          .tree-item-action {
            display: flex;
            font-size: 1.5rem;
            padding: 8px 2px;

            .icon {
              font-size: 1em;
              color: $black;
            }

            &:hover {
              .icon {
                color: $blackAlt;
              }
            }
          }
        }
      }
    }

    .MuiTreeItem-group {
      margin-left: 0;

      .tree-item {
        padding-left: 17px;
      }
    }
  }
}
