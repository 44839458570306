@import "../../styles/colours.scss";

.addCategory-input-wrapper {
	.addCategory-input {
		margin-right: 0.75em;

		.MuiInputBase-input {
			padding: 5px 8px;
			background-color: $white;
			border-radius: 0;
			border: 1px solid $black;
		}
	}

	.button {
		color: $darkGray;
		margin: 0 0.25em;
		border-radius: 0;

		&.button-add {
			background-color: $yellow;
		}

		&.button-cancel {
			background-color: $cancelButtonBackground;
		}
	}
}
