@import "../../styles/colours.scss";

.versionRecord {
	padding: 0.2em 1em 0em 1em;
	font-size: 16px;
	font-family: Poppins;

	.versionRecord-icon {
		color: $darkGray;
	}

	&.open {
		background-color: $orange;
		color: $black;
		padding-bottom: 1em;

		.versionRecord-title {
			font-weight: bold;
			margin-bottom: 0.5em;
		}

		.versionRecord-icon {
			color: $black;
		}
	}
}
