.info-column {
	.infoFields-wrapper {
		.infoField {
			margin-bottom: 1em;

			.infoField-label {
				font-weight: bold;
				margin-right: 5px;
			}

			.infoField-value {
				font-size: 0.75em;
				word-break: break-all;
			}
		}
	}
}
