@import "../../styles/colours.scss";

.solutionsBrowser-detail-tabs {
	.tab {
		color: $blackAlt;
		border-bottom: 3px solid transparent;
		padding: 12px 16px 5px 16px;
		min-height: unset;
		font-family: "Poppins";
		font-size:20px;
		text-transform: none;
		margin-top:10px;

		&.Mui-selected {
			color: $black;
			border-bottom: 3px solid $magenta;
		}
	}
}
