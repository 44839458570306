@import "../../../styles/colours.scss";

.fileTaxonomy-tree-container {
  .fileTaxonomy-collapseAll-button {
    font-family: Poppins,sans-serif;
    font-size: 0.8em;
    color: $primaryBlue;
    cursor: pointer;
  }

  .fileTaxonomy-tree {
    .tree-item {
      .MuiTreeItem-label {
        font-size: 12px;
        font-family: Poppins,sans-serif;
      }

      & > div {
        padding: 3px 5px;
        box-sizing: border-box;
      }

      .icon {
        font-size: 2em;
      }

      &.selected {
        background-color: $primaryBlue;
        color: $white;

        .icon {
          color: $white;
        }
      }

      &:not(.selected) {
        background-color: $componentGrayBackground;
        color: $black;
      }
    }

    .MuiTreeItem-group {
      margin-left: 0;
      background-color: $componentGrayBackground;

      .tree-item {
        margin-left: 17px;
      }
    }
  }
}
