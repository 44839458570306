.help-view {
  padding-top: 2em;

  .solutionsView-groupTitle-box {
    margin-bottom: 1em;
    background-color: darkblue;
    background: linear-gradient(180deg, #3380ff, #195bc7 50%, #00368f);
    padding: 10px;
    border-radius: 5px;

    .icon {
      //stroke: black;
      font-size: 72px;
    }

    .solutionsView-groupTitle {
      padding-left: 0.5em;
    }
  }
}
