@import "../../styles/colours.scss";

.app-header {
	height: 120px;
	min-height: 120px;
	box-sizing: border-box;
	padding-right: 3em;

	.header-wrapper {
		height: 100%;
		margin-right: auto;
		margin-left: auto;
		position: relative;
		/* max-width: 1332px; */

		.header-column {
			height: 100%;

			.header-link-box {
				height: 60px;
				margin: -8px 1em 0 1em;
				border-top: 8px solid transparent;
				padding: 0 1em;
				font-size: 20px;


				&.header-link-red {
					border-color: red;
				}

				.header-link {
					font-size: 18px;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}
.SynamediaTitleImage{
    height: 30px;
	margin-left: 27px;
	align-self: center;
}
.SynamediaIconImage{
	height: 57px;
}
