@import "../../styles/colours.scss";

.browser-detail-item {
	background-color: $lightGray;
	color:black;
	font-size:16px;

	td {
		border-bottom: none;
		padding: 10px 16px;

		.icon {
			font-size: 2em;
			/* color: $white; */
		}
	}
}

.browser-detail-item-content {
	border-bottom: 0.2em solid $darkGray;	

	.browser-detail-item-content-cell {
		padding: 0;
		border: none;
	}
}
